// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounting-el-js": () => import("./../../../src/pages/accounting.el.js" /* webpackChunkName: "component---src-pages-accounting-el-js" */),
  "component---src-pages-accounting-en-js": () => import("./../../../src/pages/accounting.en.js" /* webpackChunkName: "component---src-pages-accounting-en-js" */),
  "component---src-pages-consultant-el-js": () => import("./../../../src/pages/consultant.el.js" /* webpackChunkName: "component---src-pages-consultant-el-js" */),
  "component---src-pages-consultant-en-js": () => import("./../../../src/pages/consultant.en.js" /* webpackChunkName: "component---src-pages-consultant-en-js" */),
  "component---src-pages-contact-el-js": () => import("./../../../src/pages/contact.el.js" /* webpackChunkName: "component---src-pages-contact-el-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-index-el-js": () => import("./../../../src/pages/index.el.js" /* webpackChunkName: "component---src-pages-index-el-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-legal-el-js": () => import("./../../../src/pages/legal.el.js" /* webpackChunkName: "component---src-pages-legal-el-js" */),
  "component---src-pages-legal-en-js": () => import("./../../../src/pages/legal.en.js" /* webpackChunkName: "component---src-pages-legal-en-js" */),
  "component---src-pages-payroll-el-js": () => import("./../../../src/pages/payroll.el.js" /* webpackChunkName: "component---src-pages-payroll-el-js" */),
  "component---src-pages-payroll-en-js": () => import("./../../../src/pages/payroll.en.js" /* webpackChunkName: "component---src-pages-payroll-en-js" */),
  "component---src-pages-team-el-js": () => import("./../../../src/pages/team.el.js" /* webpackChunkName: "component---src-pages-team-el-js" */),
  "component---src-pages-team-en-js": () => import("./../../../src/pages/team.en.js" /* webpackChunkName: "component---src-pages-team-en-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

